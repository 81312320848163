nav.menu-header.close {
    height: 1em;
}

.abmex-main-container {
    display: flex;
    flex-direction: row;
    transition: all 0.3s ease-in-out;

    .container {
        overflow: auto;
        background-color: transparent;
    }

    main {
        height: calc(100vh - 3.1em);
        width: 100%;
        //
        position: relative;
    }
}

@import '../settings/custom-variables';

@media (max-width: 992px) {
    .container {
        max-width: 100%;
    }
}

/* 
    Buttons
*/
.btn {
    font-weight: 600;
    font-size: 1em;
    border-radius: 3em;
    padding: 0.625rem 1.1875rem;
    line-height: 1.5;
    user-select: none;
    vertical-align: middle;
    border: 1px solid transparent;
}

@each $color, $value in $theme-colors {
    .btn-text-#{$color} {
        color: $value;
        background-color: transparent;
        border-color: transparent;

        &:hover {
            color: $value;
            background-color: #f5f2f2;
            border-color: transparent;
        }
    }

    .btn-outline-#{$color} {
        color: $value;
        background-color: transparent;
        border-color: $value;
        border-width: 2px;

        &:hover,
        &:active {
            color: $value !important;
            background-color: rgba($value, 0.2) !important;
            border-color: $value !important;
        }

        &:focus {
            box-shadow: 0 0 0 0.2rem rgba($value, 0.5);
        }
    }

    .btn-#{$color} {
        color: #fff;
        background-color: $value;
        border-color: $value;

        &:hover,
        &:active {
            color: #fff;
            background-color: darken($value, 10%);
            border-color: darken($value, 10%);
        }
    }
}

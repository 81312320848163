
@import "../../../assets/scss/settings/theme-variables";
$width_menu: 250px;

ul.abmex-navbar {
    list-style-type: none;
    margin: 0;
    padding: 0;
    flex-direction: column;
    position: relative;
    z-index: 1;
    display: flex;
    flex-wrap: wrap;

    li.nav-item {
        position: relative;
        button.nav-link {
            width: calc(70px - 20px);
            padding: 7px 10px;
            transition: all 0.3s ease-in-out;
            background-color: transparent;
            border: none;

            color: #808080;
            margin: 5px 10px;
            border-radius: 0.25rem;
            display: block;
            text-align: left;
            cursor: pointer;

            .nav-text {
                // transition: all 0.3s ease-in-out;
                font-weight: 600;
                position: absolute;
                top: 15px;
                transform-origin: 0 0;
                transform: rotateY(-90deg);
                opacity: 0;
            }

            .nav-icon {
                font-size: 16px;
                padding: 0;
                margin-right: 5px;
                border-radius: 4px;
                width: 30px;
                display: inline-flex;
                align-items: center;
                height: 30px;
                text-align: center;
                justify-content: center;
            }

            .nav-right-icon {
                font-size: 16px;
                padding: 0;
                border-radius: 4px;
                width: 30px;
                display: inline-flex;
                align-items: center;
                height: 30px;
                text-align: center;
                justify-content: center;
                float: right;
                margin-bottom: -40px;

                svg {
                    font-size: 1.2rem;
                    color: $neutral-3;
                }
            }
        }
        &.active {
            button.nav-link {
                background-color: var(--primary);
                color: #fff;
                .nav-right-icon {
                    svg {
                        color: $neutral-7;
                    }
                }
            }

            ul.subitem {
                display: block;
            }
        }

        ul.subitem {
            display: none;
            position: relative;
            list-style-type: none;
            margin: 0;
            padding: 0;

            li {
                color: #808080;
                button {
                    position: relative;
                    opacity: 1;
                    background: none;
                    border: none;
                    color: inherit;
                    text-align: left;
                    padding: 0.5em 1em;
                    margin: 0;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    white-space: nowrap;
                    cursor: pointer;

                    svg {
                        margin-right: 1em;
                        margin-left: 1em;
                    }

                    &.active {
                        color: var(--primary);
                    }

                    &.disabled {
                        opacity: 0.5;
                        pointer-events: none;
                        cursor: not-allowed;
                    }
                }
            }
        }
    }
}

aside {
    width: $width_menu;
    height: calc(100% - 50px);
    transition: all 0.3s ease-in-out;
    background-color: #2c2b2c;
    justify-content: center;
    overflow: auto;
    position: absolute;
    display: block;
    z-index: 1024;
    height: calc(100% - 50px);

    &::-webkit-scrollbar {
        display: none;
    }

    .sidebar-avatar .text {
        transition: opacity 0.1s ease-out;
        opacity: 1;
        height: auto;
    }

    @media screen and (min-width: 991px) {
        &:hover,
        &:not(.mini) {
            display: block;
            .nav-link {
                width: calc(100% - 30px) !important;
                .nav-text {
                    opacity: 1 !important;
                    transform: rotateY(0deg) !important;
                }
            }
        }

        & ~ .abmex-main-container {
            margin-left: $width_menu;
        }

        &.mini ~ .abmex-main-container {
            margin-left: 70px;
        }
    }

    @media screen and (max-width: 990px) {
        .nav-link {
            width: calc(100% - 30px) !important;
            .nav-text {
                opacity: 1 !important;
                transform: rotateY(0deg) !important;
            }
        }
    }

    &.mini {
        .nav-right-icon {
            opacity: 0;
        }
        @media only screen and (min-width: 990px) {
            width: $width_menu;
            &:not(:hover) {
                width: 70px;
                .text-user {
                    transform: rotateY(-90deg);
                    transform-origin: 0 0;
                    height: 0;
                    opacity: 0;
                }
                .icons {
                    flex-direction: column;
                }

                ul.subitem {
                    &::before {
                        content: '';
                        width: 2px;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: calc(70px / 2);
                        z-index: 5;
                        background: rgba(151, 167, 193, 0.2);
                    }
                }

                ul.subitem button {
                    color: transparent !important;
                    white-space: nowrap;
                    opacity: 0;
                    &.active::before {
                        content: '';
                        top: 10px;
                        left: 31px;
                        border-radius: 50%;
                        background: var(--primary);
                        padding: 5px;
                        z-index: 6;
                        position: absolute;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 991px) {
    aside {
        margin-left: -$width_menu;
        position: absolute;
        height: calc(100% - 50px);
    }

    aside.mini {
        margin-left: 0px;
        width: $width_menu;
    }
}

.modal-erro-service {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: brightness(90%);

    .conteudo {
        width: 50%;
        max-width: 30em;
        background-color: #fff;
        border-radius: 0.3em;

        .box-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0.7em;
            border-radius: 50%;
            cursor: pointer;
            &:hover {
                background-color: #e6e6e6;
            }
            svg {
                font-size: 1em;
                color: #b3b3b3;
            }
        }
    }
}

@import 'theme-variables';

$theme-colors: (); // change
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
    (
        'primary': $primary-color,
        'secondary': $secondary-color,
        'success': $success-color,
        'info': $info-color,
        'warning': $warning-color,
        'danger': $danger-color,
        'light': $light-color,
        'dark': $dark-color,
    ),
    $theme-colors
);

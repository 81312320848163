@import '../../assets/scss/settings/theme-variables';

.abmex-text-info {
    display: flex;
    flex-direction: column;

    strong {
        display: flex;
        align-items: center;
        color: $neutral-1;
        font-size: 1em;
        font-weight: 600;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-transform: capitalize;
    }

    strong.inverter {
        font-size: 0.8em;
        font-weight: normal;
        color: #808080;
    }

    strong.success {
        color: $success-color;
    }

    span {
        color: #808080;
        font-size: 0.8em;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    span.inverter {
        font-weight: 600;
        font-size: 1.2em;
        color: #333333;
    }

    span.success {
        color: #00b57d;
        font-weight: 600;
    }
}

@import '../../assets/scss/settings/theme-variables';

.status-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    padding: 4px 8px;
    border-radius: 20px;
    line-height: 24px;
    font-size: 0.9em;
    font-weight: 500;
}

.status-item.sm {
    font-size: 0.7em;
    width: 7em;
    height: 2em;
}

.status-item.width-fixed {
    width: 10em;
}

.status-item.success {
    color: $success-color;
    background-color: $success-light;
}

.status-item.neutral {
    color: $dark-color;
    background-color: $light-color;
}

.status-item.neutral-dark {
    color: $neutral-2;
    background-color: $neutral-5;
}

.status-item.danger {
    color: $danger-color;
    background-color: $danger-light;
}

.status-item.danger-dark {
    color: $danger-dark;
    background-color: $danger-light;
}

.status-item.warning {
    color: $warning-color;
    background-color: $warning-light;
}

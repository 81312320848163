// color list for build layouts
$default-color: #d6d6d6;
$primary-color: #ff7a01;
$primary-light: #ffdfc2;
$secondary-color: #2c2b2c;
$warning-color: #fdb225;
$warning-light: #fff0d3;
$danger-color: #ff5252;
$danger-dark: #b23a3a;
$danger-light: #ffdcdc;
$success-color: #15ce95;
$darker-success-color: #19a479;
$success-light: #d0f5ea;
$info-color: #0081e6;
$info-light: #dcebf5;
$info-dark: #2f76ae;
$light-color: #eceff1;
$lighter-color: #ffff;
$dark-color: #808080;
$neutral-color: rgba(220, 235, 245, 0.5);
$theme-font-family: 'Inter', sans-serif;
$theme-font-size: 1em;
$theme-background: #ecf0f5;
$theme-font-color: #333333;
$theme-heading-color: #37474f;
$gray-light: #2C2B2C;
$outline-light-button-color: #B3B3B3;
$background-light: #d9d9d9;

$neutral-1: #0f0e12;
$neutral-2: #4c4c4c;
$neutral-3: #808080;
$neutral-4: #b2b2b2;
$neutral-5: #e5e5e5;
$neutral-6: #f2f2f2;
$neutral-7: #ffffff;

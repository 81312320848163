/** Used to define container behavior: width, position: fixed etc... **/
.Toastify__toast-container {
    width: 500px !important;
}

/** Used to define the position of the ToastContainer **/
.Toastify__toast-container--top-left {
}
.Toastify__toast-container--top-center {
}
.Toastify__toast-container--top-right {
}
.Toastify__toast-container--bottom-left {
}
.Toastify__toast-container--bottom-center {
}
.Toastify__toast-container--bottom-right {
}

/** Classes for the displayed toast **/
.Toastify__toast {
}
.Toastify__toast--rtl {
}
.Toastify__toast--dark {
}
.Toastify__toast--default {
}
.Toastify__toast--info {
    background-color: var(--info) !important;
    .Toastify__toast-body::before {
        content: '\e88e';
    }
}
.Toastify__toast--success {
    background-color: var(--success) !important;
    .Toastify__toast-body::before {
        content: '\e5ca';
    }
}
.Toastify__toast--warning {
    background-color: var(--warning) !important;
    .Toastify__toast-body::before {
        content: '\e002';
    }
}
.Toastify__toast--error {
    background-color: var(--danger) !important;
    .Toastify__toast-body::before {
        content: '\e000';
    }
}
.Toastify__toast-body {
    padding: 0.8em !important;
    font-weight: 600 !important;
    display: flex !important;
    align-items: center !important;
    font-size: 1em !important;
    &::before {
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        font-family: 'Material Icons';
        font-weight: bold;
        font-size: 1.2em;
        margin-right: 0.5em;
    }
}

/** Classes for the close button. Better use your own closeButton **/
.Toastify__close-button {
}
.Toastify__close-button--default {
}
.Toastify__close-button > svg {
}
.Toastify__close-button:hover,
.Toastify__close-button:focus {
}

/** Classes for the progress bar **/
.Toastify__progress-bar {
}
.Toastify__progress-bar--animated {
}
.Toastify__progress-bar--controlled {
}
.Toastify__progress-bar--rtl {
}
.Toastify__progress-bar--default {
}
.Toastify__progress-bar--dark {
}

.abmex-datepicker {
    label {
        font-weight: 600;
    }
    .picker {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: calc(1.5em + 1.25rem + 2px);
        align-items: center;
        border-bottom: 2px solid #ff7a01;
        .entrada {
            width: 100px;
            cursor: pointer;
            color: #707070;
            margin-right: 1em;
            border: none;
        }
    }
    .react-datepicker__day--keyboard-selected {
        background-color: #ff7a01;
        color: #fff;
        &:hover {
            background-color: #dd6c01;
        }
    }
    .react-datepicker__day--selected,
    .react-datepicker__day--in-range {
        background-color: #ff7a01;
        color: #fff;
    }

    .react-datepicker__day--selecting-range-start {
        background-color: #ff7a01 !important;
        color: #fff;
    }

    .react-datepicker__day--selecting-range-end {
        background-color: #ff7a01 !important;
        color: #fff;
    }

    .react-datepicker__day--in-selecting-range {
        background-color: #ff7a0155;
    }

    .react-datepicker__today-button {
        background: red;
        border-top: 0px;
        cursor: pointer;
        text-align: center;
        font-weight: bold;
        padding: 5px 0;
        clear: left;
    }
}

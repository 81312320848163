body {
    font-family: $theme-font-family !important;
    font-size: $theme-font-size;
    color: $theme-font-color;
    font-weight: 400;
    position: relative;
    background-color: #fcfcfc;
    margin: 0;
    padding: 0;

    --border-color: rgb(179 179 179 / 0.5);
    --primary: #ff7a01;
    --success: #15ce95;
    --danger: #ff5252;
    --info: #0081e6;
    --warning: #fdb225;

    --pix: #37b4aa;
}

* {
    &:focus {
        outline: none;
    }
}

a {
    &:hover {
        outline: none;
        text-decoration: none;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $theme-heading-color;
    font-weight: 600;
}

h1 {
    font-size: 2.5rem;
}

h2 {
    font-size: 2rem;
}

h3 {
    font-size: 1.75rem;
}

h4 {
    font-size: 1.5rem;
}

h5 {
    font-size: 1rem;
}

h6 {
    font-size: 0.875rem;
}

p {
    font-size: 0.9em;
    margin: 0;
    margin-bottom: 0;
}

b,
strong {
    font-weight: 600;
}

.MuiFormControlLabel-root {
    color: #808080;
    margin: 0;
    label {
        padding: 0;
    }
}

.text-semi-bold {
    font-weight: 600;
}

.text-muted {
    color: #808080 !important;
}

.text-danger {
    color: var(--danger) !important;
}

.text-success {
    color: var(--success) !important;
}

.text-info {
    color: var(--info) !important;
}

.text-warning {
    color: var(--warning) !important;
}

.text-primary {
    color: var(--primary) !important;
}

.text-limit {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 150px;
}

.form-control {
    border: none;
    border-bottom: 1px solid #ced4da;
    background: no-repeat center bottom, center calc(100% - 1px);
    background-size: 0 100%, 100% 100%;
    transition: background 0s ease-out;
    padding: 0;

    &:focus {
        border-bottom-color: transparent;
        background-size: 100% 100%, 100% 100%;
        transition-duration: 0.3s;
        box-shadow: none;
        background-image: linear-gradient(to top, var(--primary) 2px, rgba(70, 128, 255, 0) 2px), linear-gradient(to top, #ced4da 1px, rgba(206, 212, 218, 0) 1px);
    }
}

form {
    .form-group {
        margin-bottom: 1.5em;
        label {
            margin: 0;
            font-weight: 600;
        }
    }
    .custom-select {
        margin-top: 0.5rem !important;
        border: 1px solid #b3b3b3 !important;
        height: calc(1.5em + 1.25rem + 2px);
        padding: 0.625rem 2.1875rem 0.625rem 1.1875rem;
        font-size: 0.875rem;

        &:focus {
            border-color: #ffbd81;
            outline: 0;
            box-shadow: 0px 0px rgba(255, 122, 1, 0.25);
        }
    }
}

.feather {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'feather' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.card-valores {
    overflow: hidden;
    padding: 10px 0;

    i {
        color: #fff;
        font-size: 80px;
        position: absolute;
        bottom: -10px;
        opacity: 0.3;
        left: -10px;
        transform: rotate(15deg);
        transition: all 0.3s ease-in-out;
    }

    &:hover {
        i {
            transform: rotate(0deg) scale(1.4);
            opacity: 0.5;
        }
    }
}

.chips-select {
    & > * {
        margin-right: 0.5em;
    }

    .MuiChip-root {
        background-color: #ffdfc2;
        color: #e26e04;
    }

    .MuiChip-deleteIcon {
        color: #e26e04;
    }

    .MuiChip-label {
        font-size: 0.9em;
        padding: 0 1.5em;
    }
}

@media (min-width: 1600px) {
    .container {
        max-width: 1900px !important;
    }
}

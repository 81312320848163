.nenhuma-venda {
    margin-top: 3em;
    padding: 3em 5em;
    border: 1px solid var(--border-color);
    background-color: #fff;
    border-radius: 0.3em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .mensagem {
        display: flex;
        flex-direction: column;
        justify-content: center;

        b {
            color: #333333;
            font-weight: bold;
            font-size: 1.2em;
        }

        span {
            margin-top: 0.3em;
            font-weight: normal;
            font-size: 1em;
        }
    }
}

@import '../../assets/scss/settings/theme-variables';

div.abmex-table {
    border-collapse: separate !important;
    border-spacing: 0 10px;
    margin-top: 1em;

    .abmex-table-label {
        &::before {
            font-size: 0.8em;
            content: attr(data-label);
            display: none;
            clear: both;
            color: rgb(161, 152, 140);
            font-weight: 600;
            text-transform: uppercase;
            margin-bottom: 0.5em;
        }
    }

    .table-topo {
        color: #b3b3b3;
        text-transform: uppercase;
        margin-bottom: 1em;
        font-size: 0.9em;
    }

    .table-corpo {
        .break {
            overflow-wrap: break-word;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        display: flex;
        align-items: center;
        border: solid 0.5px var(--border-color);
        //box-shadow: 0 2px 10px -1px rgba(69, 90, 100, 0.3);
        background-color: #fff;
        vertical-align: middle;
        padding: 0.5em 0;
        border-radius: 0.3em;
        margin-bottom: 0.5em;
    }

    .table-corpo.cursor {
        cursor: pointer;
    }

    .table-box {
        display: flex;
        align-items: center;
        width: 100%;
        margin: 0;
    }

    .table-box.cursor {
        cursor: pointer;
    }

    .table-box.hide {
        display: none;
    }

    .acoes {
        display: flex;
        align-items: center;

        span {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 2.5em;
            height: 2.5em;
            padding: 0.4em;
            border-radius: 50%;
            background-color: #e7e7e7;
            color: #808080;

            margin-left: 0.1em;
        }

        span.danger {
            &:hover {
                background-color: #ff525233;
                color: #ff5252;
            }
        }

        span.info {
            &:hover {
                color: #0081e6;
                background-color: #0073cc33;
            }
        }

        span.success {
            &:hover {
                color: #15ce95;
                background-color: #d0f5ea;
            }
        }
    }

    @media only screen and (max-width: 767px) {
        .abmex-table-label {
            &::before {
                display: flex;
            }
        }

        .table-topo {
            display: none;
        }
    }
}

.typogray-header {
    font-size: 12px;
    font-weight: 600;
    color: $neutral-4;
    font-family: 'Open Sans', sans-serif;
}

.choise-tag-abmex .chips {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    & > * {
        margin: 0.1em;
        cursor: pointer;
    }
}

div.small-preview-abmex {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.3em;

    .img-avatar {
        overflow: hidden;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        height: 3em;
        min-width: 3em;
    }

    img {
        width: 3em;
        height: 3rem;
    }

    .img-avatar.big {
        overflow: hidden;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        height: 4em;
        width: 4em;

        img {
            height: 4em;
            width: 4em;
        }
    }

    .desc {
        margin-left: 0.5em;
        display: flex;
        flex-direction: column;

        .title {
            display: flex;
            flex-direction: row;
            align-items: center;
            b {
                display: flex;
                align-items: center;
                margin: 0;
                font-weight: 600;
                white-space: nowrap;
                text-overflow: ellipsis;
                text-transform: capitalize;
            }

            a {
                margin-left: 0.3em;
                color: #333333;

                &:hover {
                    color: #15ce95;
                }
            }
        }

        span {
            margin: 0;
            color: #808080;
            font-size: 0.8em;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 250px;
        }
    }
}

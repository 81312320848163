@import '../../assets/scss/settings/theme-variables';

.chip {
    background-color: $neutral-5;
    border-radius: 20px;
    color: $neutral-1;
    width: max-content;
    padding: 0.2em 1em;
    font-size: 0.85em;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    span {
        padding: 0.2em 0.5em;
    }

    button {
        border: none;
        background-color: transparent;
    }
}

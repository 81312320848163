header.menu-header {
    display: flex !important;
    background-color: #2c2b2c;
    align-items: center;
    flex-wrap: row nowrap;
    justify-content: flex-start;
    width: 100%;

    z-index: 1000;
    height: 50px;

    .left {
        position: relative;
        width: calc(19em - 5em);
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 1000;
        height: 50px;

        span {
            padding: 0.5em;
            cursor: pointer;
        }

        svg {
            color: #fff;
        }
    }

    .right {
        display: flex;
        align-items: center;
        flex-basis: 100%;
        flex-grow: 1;
        align-items: center;

        z-index: 1000;
        height: 50px;
        justify-content: flex-end;
        span {
            margin-right: 2em;
            padding: 0.5em;
            cursor: pointer;
        }

        svg {
            color: #fff;
        }
    }

    .logo {
        height: 70px;

        svg {
            width: 100%;
            height: 100%;
        }
    }

    .btn-mobile-menu {
        display: none;
    }

    .btn-menu {
        position: absolute;
        right: 0;
    }

    button {
        background-color: transparent;
        border: none;
    }

    @media screen and (max-width: 990px) {
        flex-wrap: wrap;
        justify-content: space-between;
        .right {
            background-color: #fff;
            box-shadow: 0 2px 10px rgba(69, 90, 100, 0.3);

            svg {
                color: #2c2b2c;
            }
        }

        .left {
            padding: 0em 1em;
            width: 100%;
        }

        .btn-mobile-menu {
            display: flex;
            align-items: center;
        }

        .btn-menu {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
